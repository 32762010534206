import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './scss/custom.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Mobile from './websocket/mobile';
import Main from './websocket/main';
import Background from './background/Background';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Mobile />,
    },
    /*
    {
        path: 'mobile',
        element: <Mobile />,
    },
    */
    {
        path: 'background',
        element: <Background />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
