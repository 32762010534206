import './background.css';
import myFont from './Noto Sans SC Thin_Regular.json';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { Matrix4, Vector3 } from 'three';
import * as THREE from 'three';
import CustomShaderMaterial from 'three-custom-shader-material';
import { patchShaders } from 'gl-noise/build/glNoise.m';
import { EffectComposer, TiltShift2, Noise } from '@react-three/postprocessing';
import { easing } from 'maath';
import { useRef, useEffect, useState } from 'react';
import { BlendFunction } from 'postprocessing';
extend({ TextGeometry });
const shader = {
    vertex: `
    uniform float uTime;
    varying float vVisibility;
    varying vec3 vViewNormal;

    void main() {
      vec3 n = gln_curl(position + uTime * 500.);
			vec3 _viewNormal = normalMatrix * normal;
      vViewNormal = _viewNormal;
			vec4 _mvPosition = modelViewMatrix * vec4(position, 1.);

    	float visibility = step(-0.1, dot(-normalize(_mvPosition.xyz), normalize(_viewNormal)));
      vVisibility = visibility;

      csm_Position = position + (normal * n * 0.1);
    }
    `,
    fragment: /* glsl */ `
      varying float vVisibility;
      varying vec3 vViewNormal;
  
      void main() {
  
        vec2 uv = vec2(gl_PointCoord.x, 1. - gl_PointCoord.y);
        vec2 cUV = 2. * uv - 1.;
        float a = .15 / length(cUV);
        float alpha = 1.;
        if(a < 0.15) alpha = 0.;
  
        csm_DiffuseColor = vec4(vViewNormal, (vVisibility + 0.01) * alpha);
      }
  
  
      `,
};
const font = new FontLoader().parse(myFont);
const textsample = '白鸟';
const randomChar = () => textsample;
const randomChar_ = () =>
    Array.from({ length: 2 }, () =>
        textsample.charAt(Math.floor(Math.random() * textsample.length))
    ).join('');

function scale(number, inMin, inMax, outMin, outMax) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}
// 'black' '#444', '#777' '#444', #999 #444
const color1 = '#444';
const color2 = 'black'; // '#dbdbdb';
const campos = [0, 0, 40];
const fogIntensity = 300;
const meshPos = [0, 0, -130];
let check = true;

export default function Background() {
    let lastrandom = [0, 0];
    const mesh = useRef({});
    const [textColor, setTextColor] = useState(color1);
    const [bgColor, setBgColor] = useState(color2);
    const [fog, setFog] = useState(90);
    const randomShear = () => {
        if (mesh.current) {
            const random = [
                scale(Math.random(), 0, 1, -0.5, 0.5),
                scale(Math.random(), 0, 1, 0.5, -0.5),
            ];
            console.log(mesh.current);
            const matrix = new Matrix4();
            matrix.makeShear(
                0,
                0,
                0,
                0,
                random[0] - lastrandom[0],
                random[1] - lastrandom[1]
            );
            mesh.current.geometry.applyMatrix4(matrix);
            lastrandom = random;
        }
    };
    const [text, setText] = useState(randomChar());

    return (
        <main>
            {' '}
            {/*<div className="container">
                <button
                    onClick={() => {
                        setText(randomChar);
                    }}
                >
                    Random Characters
                </button>
                <button onClick={randomShear}>Random Shadow</button>
                <button
                    onClick={() => {
                        if (check) {
                            setFog(35);
                            setTextColor(color2);
                            setBgColor(color1);
                        } else {
                            setFog(120);
                            setTextColor(color1);
                            setBgColor(color2);
                        }
                        check = !check;
                    }}
                >
                    Change Color
                </button>
                </div>*/}
            <Canvas
                eventSource={document.getElementById('root')}
                eventPrefix="client"
                shadows
                camera={{ position: campos, fov: 80 }}
            >
                <fog attach="fog" args={[bgColor, fog, fogIntensity]} />*
                <color attach="background" args={[bgColor]} />
                <spotLight
                    position={[20, 20, 10]}
                    penumbra={1}
                    castShadow
                    angle={0.2}
                />
                <MainText
                    mesh={mesh}
                    text={text}
                    textColor={textColor}
                    bgColor={bgColor}
                />
                <EffectComposer disableNormalPass>
                    <TiltShift2 blur={0.1} />
                    <Noise premultiply blendFunction={BlendFunction.ADD} />
                </EffectComposer>
                <Rig />
            </Canvas>
        </main>
    );
}

function Rig() {
    useFrame((state, delta) => {
        easing.damp3(
            state.camera.position,
            [
                Math.sin(-state.pointer.x) * 5,
                state.pointer.y * 3.5,
                15 + Math.cos(state.pointer.x) * 10,
            ],
            0.2,
            delta
        );
        state.camera.lookAt(0, 0, 0);
    });
}

function MainText({ mesh, text, textColor, bgColor, ...rest }) {
    useEffect(() => {
        if (mesh.current) {
            //
            mesh.current.geometry.computeBoundingBox();
            const center = mesh.current.geometry.boundingBox.getCenter(
                new Vector3()
            );
            console.log(center);
            //
            mesh.current.geometry.translate(-center.x, -center.y, 0);
        }
    }, [text, textColor, bgColor, mesh]);
    return (
        <mesh
            position={meshPos} //-110
            scale={[-1, -1, -1]}
            rotation={[-Math.PI / 20, -Math.PI / 20, Math.PI]}
            ref={mesh}
        >
            <textGeometry
                args={[
                    text,
                    {
                        font,
                        size: 150,
                        height: 500,
                        //bevelEnabled: true,
                        bevelThickness: 0,
                        bevelSize: -0.2,
                        bevelOffset: -0.7,
                        bevelSegments: 20,
                    },
                ]}
            />
            <CustomShaderMaterial
                baseMaterial={THREE.MeshStandardMaterial}
                vertexShader={patchShaders(shader.vertex)}
                silent
                uniforms={{
                    uTime: {
                        value: 0,
                    },
                }}
                emissive={textColor}
            />
        </mesh>
    );
}
