import io from 'socket.io-client';
import { useEffect, useRef, useState } from 'react';

const socket = io(`${process.env.REACT_APP_SERVER_URL}/mobile`, {
    autoConnect: false,
});

export default function Mobile() {
    const [slide, setSlide] = useState(1);
    const [data, setData] = useState({});
    const [role, setRole] = useState(0);

    useEffect(() => {
        function roleReceive(role_received) {
            setRole(role_received);
        }
        if (slide === 4) {
            socket.on('role', roleReceive);
            socket.emit('mobile_input', data);
        }
        return () => {
            socket.off('finished', roleReceive);
        };
    }, [slide]);

    useEffect(() => {
        socket.connect();
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <main className="container-fluid d-flex flex-column justify-content-center align-items-center text-center">
            <Slide
                slide={slide}
                setSlide={setSlide}
                num={1}
                setData={setData}
                data={data}
            >
                <h4>
                    When you have trouble communicating with your loved ones,
                    you:
                </h4>
            </Slide>
            <Slide
                slide={slide}
                setSlide={setSlide}
                num={2}
                setData={setData}
                data={data}
            >
                <h4>When you have no one to talk to, you:</h4>
            </Slide>
            <Slide
                slide={slide}
                setSlide={setSlide}
                num={3}
                setData={setData}
                data={data}
                words={['Home', 'Job', 'Bird']}
            >
                <h4>WORD ASSOCIATION: What comes to mind when you see…</h4>
            </Slide>
            <Slide
                isQuestion={false}
                slide={slide}
                setSlide={setSlide}
                num={4}
                setData={setData}
                data={data}
            >
                <div>
                    <h4>Thank you</h4> <p>We've received your responses</p>
                </div>
            </Slide>
            {slide === 5 && (
                <div className="d-flex flex-column gap-3">
                    <h4>Assigned role</h4>{' '}
                    <div
                        style={{
                            width: '500px',
                            height: '500px',
                            backgroundColor: role == 1 ? 'red' : 'blue',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxWidth: '60vw',
                            maxHeight: '60vw',
                            borderRadius: '20px',
                            color: 'white',
                            fontSize: '2em',
                        }}
                    >
                        叙述者 {role}
                    </div>
                </div>
            )}
        </main>
    );
}
// cannot leave blank
function Slide({
    slide,
    setSlide,
    num,
    children,
    setData,
    data,
    words = null,
    isQuestion = true,
}) {
    const input1 = useRef();
    const input2 = useRef();
    const input3 = useRef();

    return (
        slide === num && (
            <div
                className="d-flex flex-column justify-content-between align-items-center text-center"
                style={{ padding: '0 10vw', height: '70vh', maxWidth: '800px' }}
            >
                <div className="d-flex flex-column justify-content-between h-75">
                    {children}
                    {isQuestion && (
                        <div className="d-flex flex-column gap-3">
                            <div className="input-group">
                                {words && (
                                    <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                    >
                                        {words[0]}
                                    </span>
                                )}
                                <input
                                    ref={input1}
                                    type="text"
                                    className="form-control"
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                            <div className="input-group">
                                {words && (
                                    <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                    >
                                        {words[1]}
                                    </span>
                                )}
                                <input
                                    ref={input2}
                                    type="text"
                                    className="form-control"
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                            <div className="input-group">
                                {words && (
                                    <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                    >
                                        {words[2]}
                                    </span>
                                )}
                                <input
                                    ref={input3}
                                    type="text"
                                    className="form-control"
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <button
                    type="button"
                    className="border-0 bg-transparent fw-bold"
                    style={{ fontSize: '1.5em' }}
                    onClick={() => {
                        if (isQuestion) {
                            const placeholder = `Please fill in this response`;
                            const check1 = Validate(input1.current.value);
                            const check2 = Validate(input2.current.value);
                            const check3 = Validate(input3.current.value);
                            if (check1) {
                                input1.current.placeholder = placeholder;
                            }
                            if (check2) {
                                input2.current.placeholder = placeholder;
                            }
                            if (check3) {
                                input3.current.placeholder = placeholder;
                            }
                            if (check1 || check2 || check3) {
                                return;
                            }
                        }
                        setSlide(num + 1);
                        isQuestion &&
                            setData({
                                [`${num}`]: [
                                    input1.current.value,
                                    input2.current.value,
                                    input3.current.value,
                                ],
                                ...data,
                            });
                    }}
                >
                    Next
                </button>
            </div>
        )
    );
}

function Validate(text) {
    return text == '';
}
