import io from 'socket.io-client';
import { Fragment, useEffect, useState } from 'react';
import qrcode from '../qr.png';

const socket = io(`${process.env.REACT_APP_SERVER_URL}/main`, {
    autoConnect: false,
});

console.log(process.env.REACT_APP_SERVER_URL);

export default function Main() {
    const [slide, setSlide] = useState(1);
    const [data, setData] = useState({});

    useEffect(() => {
        function receiveData(data_received) {
            setData((data) => ({ ...data_received, ...data }));
            setSlide((slide) => slide + 1);
        }
        if (slide === 3) {
            socket.connect();
            socket.on('finished', receiveData);
            console.log('socket connected');
        }
        if (slide === 5) {
            socket.disconnect();
            console.log('socket disconnected');
        }

        console.log(slide);
    }, [slide]);

    useEffect(() => {
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <main className="container-fluid d-flex flex-column justify-content-center align-items-center text-center">
            {slide === 1 && (
                <Slide onClick={() => setSlide(2)}>
                    <h2>Karaoke Poetry</h2>
                    <p>
                        "Bai Niao" by Yeng Pway Ngon <br></br> A two-player
                        participatory poetry reading
                    </p>
                </Slide>
            )}
            {slide === 2 && (
                <Slide onClick={() => setSlide(3)}>
                    <p>1. Read the lines marked with your assigned colour.</p>
                    <p>
                        2. Lines in{' '}
                        <span style={{ color: '#00D897' }}>green</span> should
                        be read together{' '}
                    </p>
                    <p>
                        3. At certain points, you may be asked to make choices
                        or perform actions
                    </p>
                </Slide>
            )}
            {(slide === 3 || slide === 4) && (
                <Slide>
                    <p>
                        Please scan this QR code to answer a few questions
                        before you begin:
                    </p>
                    <img src={qrcode} alt="logo" width={200}></img>
                    <p>
                        This work is a collaboration.  Take your time to enjoy
                        this piece.
                    </p>
                </Slide>
            )}
            {slide === 5 && (
                <Slide onClick={() => setSlide(6)} btn_text="Start">
                    <p>Ready? Click Start</p>
                </Slide>
            )}
        </main>
    );
}

export function Slide({ onClick = false, children, btn_text = 'Next' }) {
    return (
        <div className="h-50 w-50 d-flex flex-column justify-content-between align-items-center text-center">
            <div className="d-flex flex-column gap-4 align-items-center">
                {children}
            </div>
            {onClick && (
                <button
                    type="button"
                    className="border-0 bg-transparent fw-bold"
                    onClick={onClick}
                >
                    {btn_text}
                </button>
            )}
        </div>
    );
}
